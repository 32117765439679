import React from 'react'

import './modal.scss'

interface Props {
  isOpen: boolean
  closeModal: () => void
  children: React.ReactNode
}

const Modal: React.FC<Props> = ({ isOpen, children }) => {
  if (typeof window !== 'undefined') {
    isOpen
      ? (document.body.style.overflowY = 'hidden')
      : (document.body.style.overflowY = 'scroll')
  }

  if (!isOpen) return null

  return <div className="modal">{children}</div>
}

export default Modal
