import React, { useState } from 'react'
import Modal from '.'

interface ModalContextType {
  isOpen: boolean
  openModal: (content: React.ReactNode) => void
  closeModal: () => void
  modalContent: React.ReactNode | null
}

const ModalContext = React.createContext<ModalContextType>({
  isOpen: false,
  openModal: () => {},
  closeModal: () => {},
  modalContent: null,
})

type Props = {
  children: React.ReactNode
}

export const ModalProvider: React.FC<Props> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [modalContent, setModalContent] = useState<React.ReactNode | null>(null)

  const openModal = (content: React.ReactNode) => {
    setModalContent(content)
    setIsOpen(true)
  }

  const closeModal = () => {
    setIsOpen(false)
    setModalContent(null)
  }

  return (
    <ModalContext.Provider
      value={{ isOpen, openModal, closeModal, modalContent }}
    >
      {children}

      <Modal isOpen={isOpen} closeModal={closeModal}>
        {modalContent}
      </Modal>
    </ModalContext.Provider>
  )
}

export const useModal = () => {
  const context = React.useContext(ModalContext)
  if (!context) {
    throw new Error('useModal must be used within a ModalProvider')
  }
  return context
}
