import React from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { ModalProvider } from './src/components/modal/provider'

export const onClientEntry = () => {
  const urlContainsUppercase = new RegExp(/[A-Z]/g).test(window.location.href)

  if (urlContainsUppercase) {
    window.location.replace(window.location.href.toLocaleLowerCase())
  }
}

export const wrapRootElement = ({ element }) => (
  <GoogleReCaptchaProvider reCaptchaKey="6LellREqAAAAAO3aksDeowvq2rJSlzLeEwZdXcvb">
    <ModalProvider>{element}</ModalProvider>
  </GoogleReCaptchaProvider>
)
